import { ThemeOptions, createTheme } from '@mui/material'
import { ZipJobLandingTheme } from '../../../zipjob.com/src/styles'
import merge from 'lodash/merge'
import './fonts.css'

// The type on this is to make Typescript shut up, remove it when we start
// defining values.
export const colors = {
  blue: {
    10: '#eaF6ff',
    50: '#1a91f0',
    60: '#1170cd',
    80: '#0F3871',
    100: '#e4ebf3',
    200: '#eaf6ff',
    250: '#c3e5fe',
    275: '#92cdfc',
    300: '#38b5fa',
    400: '#0da1ec',
    450: '#1a91f0',
    475: '#1170cd',
    485: '#10529b',
    500: '#0a80bc',
    600: '#1E2532',
    700: '#09143b',
    a400: '#1096da',
    a500: '#007fbf',
    a600: '#051f55',
  },
  gray: {
    100: '#f6f7fa',
    200: '#b7c2d8',
    300: '#e4e8f0',
    400: '#8992a4',
    500: '#818b9e',
    a200: '#d2d9e7',
    a300: '#a9afbc',
    a400: '#b1b7c3',
  },
  green: {
    10: '#e7f4ed',
    40: '#48ba75',
    50: '#339d5d',
    400: '#0ed265',
    500: '#00b67a',
  },
  orange: {
    50: '#ee571d',
    300: '#f2aa78',
    400: '#ee8d49',
    500: '#ea701a',
  },
  red: {
    10: '#ffeaec',
    60: '#DA0C22',
    300: '#FB4458',
    400: '#ff0035',
  },
  white: {
    100: '#ffffff',
    200: '#fafafc',
    300: '#f6f7fa',
    400: '#e4e8f0',
  },
  yellow: {
    400: '#ffcc00',
  },
  amber: {
    10: '#fff2cc',
    40: '#ec930c',
    50: '#cf760d',
    90: '#3e1d0a',
  },
  neutral: {
    5: '#f7f9fc',
    10: '#eff2f9',
    20: '#d9deeb',
    15: '#e7eaf4',
    30: '#bec4d5',
    40: '#9FA6BB',
    50: '#828ba2',
    60: '#656e83',
    80: '#303848',
    90: '#1e2532',
  },
  // Not the ideal way to do it, but we have conflicts within the TR and ZJ palettes
  // @TODO: Standardise the theme palette for all websites
  switch: {
    active: '#0da1ec',
  },
  dns: {
    background: '#f6f7fa',
    text: '#1e2532',
    statusBackgroundSuccess: '#e4e8f0',
    statusBackgroundFailure: '#ffeaec',
    statusMessageSuccess: '#818b9e',
    statusMessageFailure: '#da0c22',
  },
} as const

export const TopResumeTheme = createTheme(
  merge({}, ZipJobLandingTheme, {
    themeName: 'TopResume',
    colors,
    palette: {
      primary: {
        light: colors.blue[100],
        main: colors.blue[450],
        dark: colors.blue[700],
        contrastText: colors.blue.a600,
      },
      default: {
        light: colors.blue[100],
        main: colors.blue[450],
        dark: colors.blue[700],
        contrastText: colors.blue.a600,
      },
      secondary: {
        light: colors.gray[200],
        main: colors.gray[400],
        dark: colors.gray[500],
      },
      error: {
        light: colors.red[400],
        main: colors.red[400],
        dark: colors.red[400],
      },
      warning: {
        light: colors.yellow[400],
        main: colors.yellow[400],
        dark: colors.yellow[400],
      },
      info: {
        light: colors.blue[400],
        main: colors.blue[400],
        dark: colors.blue[400],
      },
      success: {
        light: colors.green[400],
        main: colors.green[400],
        dark: colors.green[400],
      },
      text: {
        primary: colors.blue[600],
        secondary: colors.blue[600],
        disabled: colors.blue[600],
        hint: colors.blue[600],
      },
      action: {
        active: colors.blue[400],
        hover: colors.blue[300],
        selected: colors.blue[500],
        disabled: colors.blue[400],
        focus: colors.blue[100],
      },
      background: {
        default: colors.white[100],
        paper: colors.white[200],
      },
      divider: colors.gray[300],
      common: {
        white: colors.white[300],
      },
      white: {
        main: '#fff',
      },
    },
    typography: {
      fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
      fontSize: 16,
      htmlFontSize: 16,
      h1: {
        fontFamily: 'Tex Gyre, Helvetica, Arial, sans-serif',
        fontSize: '2.75rem',
        fontWeight: 700,
        lineHeight: '1.2',
        margin: '1.125rem 0',
        '@media (max-width:600px)': {
          fontSize: '1.875rem',
          margin: '1rem 0',
        },
      },
      h2: {
        fontFamily: 'Tex Gyre, Helvetica, Arial, sans-serif',
        fontSize: '1.875rem',
        fontWeight: 700,
        lineHeight: '1.4',
        margin: '.625rem 0',
        '@media (max-width:600px)': {
          fontSize: '1.625rem',
        },
      },
      h3: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '1.375rem',
        fontWeight: 700,
        lineHeight: '1.45',
        margin: '.125rem 0',
        '@media (max-width:600px)': {
          fontSize: '1.25rem',
        },
      },
      h4: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.4',
        margin: '0',
        '@media (max-width:600px)': {
          fontSize: '1.25rem',
          lineHeight: '1.4',
        },
      },
      h5: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '.875rem',
        fontWeight: 600,
        lineHeight: '1.4',
        margin: '0',
        textTransform: 'uppercase',
      },
      h6: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '.875rem',
        fontWeight: 600,
        lineHeight: '1.4',
        textTransform: 'uppercase',
        margin: '0',
      },
      body1: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '1.0625rem',
        fontWeight: 400,
        lineHeight: '1.5294',
        '@media (max-width:600px)': {
          fontSize: '1.0625rem',
          lineHeight: '1.5294',
        },
      },
      body2: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '1.0625rem',
        fontWeight: 400,
        lineHeight: '1.5294',
        '@media (max-width:600px)': {
          fontSize: '1.0625rem',
          lineHeight: '1.5294',
        },
      },
      button: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        color: colors.white[100],
        fontSize: '19px',
        fontWeight: 400,
      },
      caption: {
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '.88rem',
        lineHeight: '1.57',
      },
      overline: {
        color: colors.blue[400],
        fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
        fontSize: '.88rem',
        fontWeight: 700,
        letterSpacing: '.07em',
        lineHeight: '1.4',
        textTransform: 'uppercase',
        '@media (max-width:600px)': {
          fontSize: '.88rem',
          lineHeight: '1.4',
        },
      },
    },
    shape: {
      borderRadius: 6,
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          color: 'primary',
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            marginBlock: '.1em',
            borderStyle: 'none',
            borderRadius: '32px',
            minWidth: '0',
            padding: '14px 20px',
            textTransform: 'inherit',
            '&:hover': {
              boxShadow: '0 .56rem 1.25rem -.5rem rgb(32 33 36 / 30%)',
            },
            '&:focus': {
              outlineStyle: 'solid',
              outlineColor: colors.blue[450],
              outlineWidth: '2px',
              outlineOffset: '3px',
              border: 'none',
            },
            '&.Mui-disabled': {
              opacity: 0.35,
              filter: 'saturate(0%)' as any,
            },
            '&&': {
              marginBlock: '.2em',
            },
          },
          outlined: {
            border: 'none',
            padding: '14px 20px',
            color: colors.blue[450],
            lineHeight: 'normal',
            textTransform: 'inherit',
            background: colors.blue[200],

            '&:hover': {
              border: 'initial',
              backgroundColor: colors.blue[250],
              color: colors.blue[450],
            },
            '&:active': {
              border: 'initial',
              backgroundColor: colors.blue[275],
              color: colors.blue[450],
            },
          },
          outlinedPrimary: {
            border: 'none',
            color: colors.blue[450],
            '&:hover': {
              border: 'none',
              backgroundColor: colors.blue[250],
              color: colors.blue[450],
            },
            '&:active': {
              border: 'none',
              backgroundColor: colors.blue[275],
              color: colors.blue[450],
            },
          },
          outlinedSecondary: {
            backgroundColor: colors.blue[200],
            color: colors.blue[450],
            '&:active': {
              backgroundColor: colors.blue[275],
            },
            '&:hover': {
              backgroundColor: colors.blue[250],
            },
          },
          contained: {
            border: 'none',
          },
          containedPrimary: {
            color: colors.white[100],
            border: 'none',
            borderColor: 'unset',
            '&:active': {
              backgroundColor: colors.blue[485],
            },
            '&:hover': {
              border: 'none',
              borderColor: 'unset',
              backgroundColor: colors.blue[475],
            },
            '&:focus': {
              backgroundColor: colors.blue[450],
            },
          },
          containedSecondary: {
            backgroundColor: colors.white[100],
            borderColor: colors.white[100],
            color: colors.blue[400],
            '&:active': {
              backgroundColor: 'rgba(13,161,236,0.2)',
              borderColor: 'rgba(13,161,236,0.2)',
            },
            '&:hover': {
              backgroundColor: 'rgba(16,150,218,0.1)',
              borderColor: 'rgba(16,150,218,0.1)',
            },
          },
          text: {
            color: colors.blue[400],
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.blue[700],
            },
          },
          textPrimary: {
            color: colors.blue[400],
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.blue[700],
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: {
            '&:hover': {
              color: colors.blue.a400,
              textDecoration: 'none',
            },

            '&:active': {
              color: colors.blue.a500,
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          input: {
            border: '2px solid transparent',
            borderRadius: '0',

            '&:focus': {
              borderColor: colors.blue[450],
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          disableAnimation: true,
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            '& *': {
              '&:focus': {
                outline: `${colors.blue[450]} auto 1px`,
              },
            },
          },
          body: {
            fontSize: '1.0625rem',
            letterSpacing: '-.01em',
            lineHeight: 1.5294,
            '@media only screen and (max-width: 600px)': {
              fontSize: '16px',
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(34,34,34,0.9)',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorDockedBottom: {
            borderTop: 0,
          },
        },
      },
      MuiRating: {
        styleOverrides: {
          root: {
            color: colors.orange[400],
          },
          iconEmpty: {
            color: colors.gray[100],
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: colors.gray.a200,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            borderColor: colors.gray.a200,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: colors.gray[500],
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: '1rem',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          subheader: {
            color: colors.gray[500],
          },
          title: {
            fontWeight: 700,
          },
        },
      },
    },
  } satisfies ThemeOptions)
)

export default TopResumeTheme
